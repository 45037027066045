import { apiGet } from "~/services/api";
import getRoute from "~/utilities/configs/apiMap";
import type { PropertyItem } from "~/types/search-page-items";

export const unitPageStore = defineStore("unitPage", {
  state: () => ({
    unitPageData: null as PropertyItem | null,
  }),
  actions: {
    async getAllTokens() {
      try {
        const data = await apiGet({
          url: getRoute({ endpont: "get_blockchains" }),
        });
        if (!data) return;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
